import type { NuxtError } from '#app';

export default function handleApiBadRequestError(error: unknown) {
    if (!isNuxtError<string[]>(error))
        throw error;

    const nuxtError = error as NuxtError<string[]>;
    if (nuxtError.statusCode !== 400)
        throw error;

    //return custom errors if they exist
    if (nuxtError.data?.length)
        return nuxtError.data;

    return [nuxtError.statusMessage ?? nuxtError.message];
}